// datatable


.table-bordered {
  border: $table-border-width solid $table-border-color;
}


div.dataTables_wrapper {
  div.dataTables_filter{
    text-align: right;
    input{
      margin-left: 0.5em;
      margin-right: 0;
    }
  }
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]{
  &:first-child{
    padding-left: 0;
  }

  &:last-child{
    padding-right: 0;
  }
}


.table.dataTable {
  thead {

    .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled{
      &:before{
        left: auto;
        right: 1em;
      }
    }

    .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled{
      &:after{
        left: auto;
        right: 0.5em;
      }
    }

    tr{
      th, td{
        &.sorting_asc, &.sorting_desc, &.sorting{
          padding-left: 12px;
          padding-right: 30px;
        }
      }
    }
  }
}

